import { authHeader,authHeaderImage,isEmpty } from '../_helpers';
import { serverConstants } from '../_constants';
import { saveAs } from 'file-saver';


export const recipeService = {
    getAll,
    add,
    get,
    update,
    updateInstructions,
    delete: _delete,
    deleteSelect,
    categorySelect,
    count,
    stats,
    deleteingredient,
    deletesubrecipe,
    export_csv,
    export_xlsx,
    renderPdf,
    renderOrder,
    renderOrderCsv,
    getOrder,
    saveOrder,
    getOrders,
    deleteOrder,
    countOrders,
    updateOrder,
    sendOrder,
    deleteSelectOrder,
    updateOrderQuantity,
    roundOrderQuantity,
    addOrderIngredient,
    deleteIngredientOrder,
    updateOrderRecipeQuantity,
    updateOrderRecipeTraceability,
    deleteRecipeOrder,
    addOrderRecipe,
    deduceStockOrder,
    addStockOrder,
    destockOrder,
    destockOrderRecipes,
    stockOrderRecipes,
    createSuppliersOrders,
    updateImage,
    updateImages,
    getImage,
    deleteImage,
    deleteImages,
    recipe_weight_variation,
    labelTag,
    updateLabelTag,
    getAllMenu,
    getMenu,
    addMenu,
    updateMenu,
    deleteMenu,
    addRecipeMenu,
    updateRecipeMenu,
    deleteRecipeMenu,
    addIngredientMenu,
    updateIngredientMenu,
    deleteIngredientMenu,
    downloadMenu,
    countMenus,
    allergensMatrix,
    getAllergensRecipes,
    getOrdersRecipes,
    update_stock,
    reset_stock,
    inventory,
    updateDaysValidity,
/*     getEstimate,
    getEstimates,
    saveEstimate,
    updateEstimate,
    deleteEstimate,
    updateEstimateRecipeQuantity,
    deleteRecipeEstimate,
    addEstimateRecipe,
    sendPOS, */
};


const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };


function handleResponse(response) {
    //Tempo to avoid DELETE issue with no data
    if (response.status == 204){
        return {};
    }
    if (!response.ok) { 
        //return Promise.reject(response.statusText);
         return response.json().then(json => {
            //const error = new Error(json.message)
            //return Promise.reject(Object.assign(error, { response }))

            /*const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
            }); 
            return Promise.reject(error);*/

            if (!isEmpty(json.message)){
                 const error = Object.assign({}, json, {
                    status: response.status,
                    statusText: response.statusText,
                });
                return Promise.reject(error);
            }else{
                 const error = Object.assign({}, json, {
                    status: response.status,
                    statusText: response.statusText,
                    title: json.detail,
                });

                return Promise.reject(error);
            }
        })
    }
    return response.json();
}

function getAll(short) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (short == true){
        //Speed flat request (not nested)
        return fetch(serverConstants.BACKEND_URL+'/recipes/get_list/', requestOptions).then(handleResponse);
    }else{
        return fetch(serverConstants.BACKEND_URL+'/recipes/', requestOptions).then(handleResponse);
    }
        
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        //headers: {
        //    'Access-Control-Allow-Origin': '*',
        //    'Content-Type': 'application/json',
        //   'Accept': 'application/json',                  
        //}
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/', requestOptions).then(handleResponse);
}

function add(recipe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/', requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
	    headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/', requestOptions).then(handleResponse);
}

function deleteSelect(recipes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ids:recipes})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/delete_select/', requestOptions).then(handleResponse);
}

function count() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/count/', requestOptions).then(handleResponse);
}


function stats() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/stats/', requestOptions).then(handleResponse);
}

function update(id,recipe) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/', requestOptions).then(handleResponse);
}

function updateInstructions(id,instructions) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({instructions:instructions})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/instructions/', requestOptions).then(handleResponse);
}

function updateImage(id,image) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeaderImage(),
        body: image
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/image/', requestOptions).then(handleResponse);
}

function getImage(id,image) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/getimage/', requestOptions).then(handleResponse);
}


function deleteImage(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeaderImage(),
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/deleteimage/', requestOptions).then(handleResponse);
}

function deleteImages(id,path) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({url:path})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/deleteimages/', requestOptions).then(handleResponse);
}


function updateImages(id,image) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeaderImage(),
        body: image
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/images/', requestOptions).then(handleResponse);
}

function deleteingredient(id,id_ingredient) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({id:id_ingredient})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+id+'/ingredient/',requestOptions).then(handleResponse);;
}

function deletesubrecipe(id,id_recipe) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({id:id_recipe})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+id+'/recipe/', requestOptions).then(handleResponse);;
}



function export_csv(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/export_csv/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'recipes.csv'))
}


function export_xlsx(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/export_xlsx/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'recipes.xlsx'))
}


function renderPdf(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genRecipe/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ratatool-'+parameters.name+'.pdf'))
/*     .then(response => {
            var blob=response
            var reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                var base64data = reader.result;
                window.open(base64data);
            }
          })
    .catch(error => {
            console.error(error);
          }); */
}


function renderOrder(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genOrder/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ratatool_'+parameters.name+'.pdf'))
}

function renderOrderCsv(id,name,include_costs) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        responseType: 'blob',
        body: JSON.stringify({withCosts:include_costs}),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/csv/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ratatool_'+name+'.csv'))
}


function saveOrder(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/', requestOptions).then(handleResponse);
}

function getOrders(short,prod) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    if (short == true){
        //Speed flat request (not nested)
        if (prod == true){
            return fetch(serverConstants.BACKEND_URL+'/orders/get_prod_list/', requestOptions).then(handleResponse);
        }else{
            return fetch(serverConstants.BACKEND_URL+'/orders/get_list/', requestOptions).then(handleResponse);
        }
    }else{
        return fetch(serverConstants.BACKEND_URL+'/orders/', requestOptions).then(handleResponse);
    }
}

function deleteOrder(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/', requestOptions).then(handleResponse);
}

function getOrder(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/', requestOptions).then(handleResponse);
}

function countOrders() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/count/', requestOptions).then(handleResponse);
}

function updateOrder(id,parameters) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/', requestOptions).then(handleResponse);
}

function sendOrder(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/sendOrder/', requestOptions).then(handleResponse);
}

function deleteSelectOrder(orders) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ids:orders})
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/delete_select/', requestOptions).then(handleResponse);;
}


function updateOrderQuantity(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/update_ingredient/', requestOptions).then(handleResponse);
}

function roundOrderQuantity(id,parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/round_ingredients/', requestOptions).then(handleResponse);
}

function deleteIngredientOrder(id,parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/delete_ingredient/', requestOptions).then(handleResponse);
}

function addOrderIngredient(id,parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/add_ingredient/', requestOptions).then(handleResponse);
}

function updateOrderRecipeQuantity(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/update_recipe/', requestOptions).then(handleResponse);
}

function updateOrderRecipeTraceability(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/update_recipe_traceability/', requestOptions).then(handleResponse);
}

function deleteRecipeOrder(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/delete_recipe/', requestOptions).then(handleResponse);
}

function addOrderRecipe(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/add_recipe/', requestOptions).then(handleResponse);
}


function deduceStockOrder(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/deduce_available_items/', requestOptions).then(handleResponse);
}

function addStockOrder(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/add_received_items/', requestOptions).then(handleResponse);
}

function destockOrder(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/destock_order_items/', requestOptions).then(handleResponse);
}

function destockOrderRecipes(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/destock_order_recipes/', requestOptions).then(handleResponse);
}

function stockOrderRecipes(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/stock_order_recipes/', requestOptions).then(handleResponse);
}

function createSuppliersOrders(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/orders/'+id+'/create_supplier_orders/', requestOptions).then(handleResponse);
}


function recipe_weight_variation(id,params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/apply_weight_variation/', requestOptions).then(handleResponse);
}

function labelTag(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/get_label_tag/', requestOptions).then(handleResponse);
}

function updateLabelTag(id,params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/update_label_tag/', requestOptions).then(handleResponse);
}

function getAllMenu() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/get_list/', requestOptions).then(handleResponse);        
}

function getMenu(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/', requestOptions).then(handleResponse);        
}

function addMenu(menu) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(menu)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/', requestOptions).then(handleResponse);
}

function updateMenu(id,menu) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(menu)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/', requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function deleteMenu(id) {
    const requestOptions = {
        method: 'DELETE',
	    headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+ id +'/', requestOptions).then(handleResponse);
}

function addRecipeMenu(id,recipe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/add_recipe/', requestOptions).then(handleResponse);
}

function updateRecipeMenu(id,recipe) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/update_recipe/', requestOptions).then(handleResponse);
}

function deleteRecipeMenu(id,recipe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(recipe)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/delete_recipe/', requestOptions).then(handleResponse);
}

function addIngredientMenu(id,ingredient) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/add_ingredient/', requestOptions).then(handleResponse);
}

function updateIngredientMenu(id,ingredient) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/update_ingredient/', requestOptions).then(handleResponse);
}

function deleteIngredientMenu(id,ingredient) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ingredient)
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/'+id+'/delete_ingredient/', requestOptions).then(handleResponse);
}


function downloadMenu(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genMenu/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ratatool-menu.pdf'))
}


function countMenus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/menus/stats/', requestOptions).then(handleResponse);
}

function allergensMatrix(all,recipes,ingredients) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ all:all,
                               ids:recipes,
                               ingred_ids:ingredients}),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genMatrix/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'ratatool-matrix.pdf'))
}

function getAllergensRecipes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/allergensRecipes/', requestOptions).then(handleResponse);
        
}

function getOrdersRecipes(start,end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(serverConstants.BACKEND_URL+'/ordersRecipes/?start='+start+'&end='+end, requestOptions).then(handleResponse);
        
}


function inventory(date) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(),
        responseType: 'blob'
    };

    return fetch(serverConstants.BACKEND_URL+'/genInventoryRecipe/', requestOptions)
    .then((response) => response.blob())
    .then(blob => saveAs(blob, 'stock_'+date+'.pdf'))
/*     .then(response => {
            var blob=response
            var reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                var base64data = reader.result;
                window.open(base64data);
            }
          })
    .catch(error => {
            console.error(error);
          }); */
}


function update_stock(id,quantity) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({'stock_quantity':quantity})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/stock/', requestOptions).then(handleResponse);
}


function reset_stock() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/reset_stock/', requestOptions).then(handleResponse);
}


function updateDaysValidity(id,number_of_days) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({'days_validity':number_of_days})
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/'+ id +'/validity/', requestOptions).then(handleResponse);
}

function categorySelect(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(serverConstants.BACKEND_URL+'/recipes/category_select/', requestOptions).then(handleResponse);
}

/* function sendPOS(recipes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'ids':recipes}),
    };

    return fetch(serverConstants.BACKEND_URL+'/pos_recipe_send/', requestOptions).then(handleResponse);
}

function getEstimate(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/', requestOptions).then(handleResponse);
}

function saveEstimate(parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/', requestOptions).then(handleResponse);
}

function updateEstimate(id,parameters) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/', requestOptions).then(handleResponse);
}

function getEstimates(short) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    if (short == true){
            return fetch(serverConstants.BACKEND_URL+'/invoicings/get_list/', requestOptions).then(handleResponse);
    }else{
        return fetch(serverConstants.BACKEND_URL+'/invoicings/', requestOptions).then(handleResponse);
    }
}

function deleteEstimate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/', requestOptions).then(handleResponse);
}

function updateEstimateRecipeQuantity(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/update_recipe/', requestOptions).then(handleResponse);
}


function deleteRecipeEstimate(id,parameters) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(parameters),
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/delete_recipe/', requestOptions).then(handleResponse);
}


function addEstimateRecipe(id,parameters) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(parameters)
    };

    return fetch(serverConstants.BACKEND_URL+'/invoicings/'+id+'/add_recipe/', requestOptions).then(handleResponse);
} */
