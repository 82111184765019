import React, { Component } from 'react';
import { Table, Menu, Button, Grid, Modal,Pagination,Header,Dropdown,Popup,Input,Icon,Dimmer,Loader, Message,Image,Form,Breadcrumb } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {recipesActions,categoryRecipeActions,paymentActions,alertActions} from '../_actions'
import './Recipes.css';
import SideLeftUncoverRecipes from './SidebarRecipes';
import {history,toaster_dispatch,Capitalize,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,optimizeDecimalPart,trim,GUEST,isFloat,isNumeric,isQuantity} from '../_helpers';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_pan from '../Assets/pan.png'
import {isEmpty,deburr} from "lodash"
import './FloatingMenu.css';
import OutsideClickHandler from 'react-outside-click-handler';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';
import img_performance from '../Assets/performance.png'

const options = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

const trigger = (
    <span>
      <Icon name='ellipsis vertical'  size='large' color='teal'/>
    </span>
  )

const optionsPlus = [
    { key: 'none', text: 'OPTIONS', value: 'none' },
   /*  { key: 'view', text: 'Detailed view', value: 'view', icon: <Icon name='folder open outline' color='blue' /> }, */
    { key: 'edit', text: <FormattedMessage id="recipes.menu.options.edit.text"
                                           defaultMessage="Edit"/>, value: 'edit', icon: <Icon name='edit outline' color='blue'/> },
    { key: 'clone', text: <FormattedMessage id="recipes.menu.options.clone.text"
                                            defaultMessage="Duplicate"/>, value: 'clone', icon: <Icon name='copy outline' color='yellow'/> },
/*     { key: 'pos', text: <FormattedMessage id="recipes.menu.options.pos.text"
                                            defaultMessage="Send to POS"/>, value: 'pos', icon: <Icon name='tv' color='green'/> }, */
    { key: 'delete', text: <FormattedMessage id="recipes.menu.options.delete.text"
                                             defaultMessage="Delete"/>, value: 'delete', icon:<Icon name='trash alternate outline' color='red' /> },
  ]

  const filterOptions = [
    {
      key: 'Category',
      text: <FormattedMessage id="recipes.filter.type.category.text"
                              defaultMessage="Category"/>,
      value: 'Category',
     // label: { color: 'yellow', empty: true, circular: true },
      icon: {name:'sitemap',color:'yellow'}
    },
    {
      key: 'InternalRef',
      text: <FormattedMessage id="recipes.filter.type.internalref.text"
                              defaultMessage="Internal code"/>,
      value: 'InternalRef',
      //label: { color: 'blue', empty: true, circular: true },
      icon: {name:'tag',color:'blue'}
    }
]

class Recipes extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.hideModal = this.hideModal.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);
        this.filterTypeList=this.filterTypeList.bind(this);
        this.filterInternalRefList=this.filterInternalRefList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        this.createIngredient = this.createIngredient.bind(this);
        this.createRecipe = this.createRecipe.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        //this.submitAction=this.submitAction.bind(this);
        this.cancelAction=this.cancelAction.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.state = { 
            activeModal: null,
            filtering: false,
            currency : userIn.user.currency,
            toggled: false,
            isMobile: false,
            page: 1,
            itemsPerPage: 10,
            selectionList:[],
            actionSelected: "",
            actionModal: false,
            allowMultipleSelection:true,
            newPrice:0,
            typeSelected:'Category',
         }
         
        props.dispatch(recipesActions.getAll(true));
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(categoryRecipeActions.getAll());
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    onOptionsMenu = (e,data,index,recipe_id) => {
        e.preventDefault();
        if (data && data.value === 'view'){
            this.seeRecipe(e,recipe_id)
        }
        if (data && data.value === 'edit'){
            this.editRecipe(e,recipe_id)
        }
        if (data && data.value === 'clone'){
            this.copyRecipe(e,recipe_id)
        }
/*         if (data && data.value === 'pos'){
            this.sendPOSRecipe(e,recipe_id)
        } */
        if (data && data.value === 'delete'){
            this.deleteClickHandler(e,index)
        }
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModal: index});
    }; 

    hideModal() {
        this.setState({ activeModal: null })
        this.setState({ actionModal: false })
        this.setState({ actionModalCategory: false })
/*         this.setState({ actionModalPos: false }) */
        //this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    seeRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/recipedetails/'));
        history.push('/recipedetails/'+index);
    }

    editRecipe (event,index) {
        event.preventDefault();
        //this.props.dispatch(recipesActions.get(index,'/editrecipe/'));
        history.push('/editrecipe/'+index);
    }

    copyRecipe (event,index) {
        event.preventDefault();
        history.push('/copyrecipe/'+index);
    }

/*     sendPOSRecipe (event,index) {
        event.preventDefault();
        this.props.dispatch(recipesActions.sendPOS([index]));
        
    } */

    deleteRecipe (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(recipesActions.delete(index));
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)    
    }

    downloadRecipe (event,index) {
        event.preventDefault();
        this.props.dispatch(recipesActions.get(index,'/downloadrecipe/'+index));
    } 


    createIngredient(){
        history.push('/newingredient/');
    }

    createRecipe(){
        history.push('/newrecipe/');
    }

    createOrder(){
        history.push('/orders/');
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({codeSelected: ""}); 
    }

    filterNameList(event){
        event.preventDefault();
        
        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }
/* 
        let updatedList = this.props.recipes.items.filter((item) => {
          return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                    .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
        }); */

       
        let words = (event.target.value).normalize('NFKD').toLowerCase().split(' ')
        words = words.map(word => deburr(word))
        words = words.filter(item => item.length > 1);
        words = words.filter(item => item);

        let updatedList = this.props.recipes.items.filter((item) => {
            let clean_item_name = deburr( item.name)
            return words.some(word => (clean_item_name).normalize('NFKD').toLowerCase().includes(word))
        });

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: event.target.value});
        this.setState({categorySelected: ""});
        this.setState({ selectionList:[]});
        this.setState({codeSelected: ""});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        let category_id = data.value;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let elem = categoriesRecipe.options.find(category => category.value == category_id)
        if (elem){
            let updatedList = this.props.recipes.items.filter((item) => {
/*                 if (item.category__name){
                    return item.category__name.toLowerCase().search(elem.text.toLowerCase()) !== -1;
                } */
                if (item.category__id){
                    return item.category__id == elem.value
                }
                return null;    
            });
            this.setState({newItems: updatedList});
            this.setState({ categorySelected: {id:category_id,
                                               name: elem.text } });
           this.setState({filtering: true});
        }else{
            this.setState({ categorySelected:null})
            this.setState({filtering: false});
        }
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});
        this.setState({codeSelected: ""});
    }


    filterTypeList(event,data){
        event.preventDefault();
        this.setState({typeSelected: data.value});
        this.removeFilters(event);
    }

    filterInternalRefList(event,data){
        event.preventDefault();

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        let updatedList = this.props.recipes.items.filter((item) => {
          if (item.ref_internal){
           return item.ref_internal.toLowerCase().search(data.value.toLowerCase()) !== -1;
          }
          return null;
        });

        this.setState({newItems: updatedList});
        this.setState({filtering: true});
        this.setState({nameSelected: ""});
        this.setState({categorySelected: null});
        this.setState({ selectionList:[]});
        this.setState({codeSelected: data.value});
    }


    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && data.value === 'category'){
            this.setState({actionModalCategory: true});
        }

/*        if (selectionList.length > 0 && data.value === 'pos'){
            this.setState({actionModalPos: true});
        } */

        if (selectionList.length > 0 && data.value == 'delete'){
            this.setState({actionModal: true});
        }
    }

/*     submitAction(event){
        event.preventDefault();
        //this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
        this.setState({actionModal: true});
    } */

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(recipesActions.deleteSelect(selectionList))
        this.setState({ selectionList:[]});
    }

/*     actionSelectSendPOS(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(recipesActions.sendPOS(selectionList))
        this.setState({ selectionList:[]});
    } */


    onCategoryChange = (e,data) => {
        e.preventDefault();
        const {categoriesRecipe} = this.props;
         if (data.value===""){
            this.setState({ categorySelected: null });
         }else{
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(s => s.value === category_id)
            this.setState({ categorySelected: {id:category_id,
                                               name: elem.text } });
         }
    }

    actionSelectCategory = (event) => {
        event.preventDefault();
        const { selectionList,categorySelected } = this.state;

        const params = {
            ids: selectionList,
            elem_set: categorySelected?true:false,
            elem_id: categorySelected?categorySelected.id:-1,
        }
        this.props.dispatch(recipesActions.categorySelect(params,'/recipes'))
        this.setState({ selectionList:[]});
    }

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((ingredient,index) => {
                allList.push(ingredient.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }


    assertPriceField(price){
        if ((isFloat(price) || isNumeric(price))
          && (price != "")
          && Math.trunc(price).toString().length<10)
          return true;
        return false;
    }

    editPriceHandlerInput (event,index,recipe) {
        event.preventDefault();
        let selling_price = optimizeDecimalPart(Number(recipe.selling_price))

        this.setState({ newPrice: selling_price});

        //this.setState({ menu_recipe: recipe.id});
        this.setState({ editCellRecipePrice: true});
        this.setState({ editCellIndexRecipePrice: index});
    };


    onNewPrice = (e,item) => {
        //e.preventDefault();
        this.setState({ newPrice: e.target.value});
    }

    updatePriceOnEnter (event,recipe) {
        const {newPrice,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
                let price_origin = recipe.selling_price;
                price_origin = Number(price_origin).toFixed(3)

                const title = <FormattedMessage id="recipes.price.check.error.title"
                                                defaultMessage='Price update error'/>
                
                if (this.assertPriceField(price) && (price!=price_origin)){
                        if (active_role!= GUEST){

                            const recipe_data = {
                                id:recipe.id,
                                name:recipe.name,
                                selling_price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                            }

                            dispatch(recipesActions.update(recipe.id,recipe_data));
                            recipe.selling_price = price
                             setTimeout(() => {
                                dispatch(recipesActions.getAll(true));
                            },500);
                            
                
                        }else{
                            dispatch(alertActions.error({title:'recipes.price.privilege.error.message',
                                                        content:'recipes.price.check.error.title'}));  
                        }
                        this.setState({ editCellRecipePrice: false });
                }else{
                    if (Math.trunc(price).toString().length>=9){
                       const content =  <FormattedMessage id="recipes.price.check.error.message"
                                                         defaultMessage='Price field is invalid'/>
                        dispatch(alertActions.error({title:'recipes.price.check.error.title',
                                                    content:'recipes.price.check.error.message'}));   
                    }
                    this.setState({ editCellRecipePrice: false });
                }
        }
    }


    updatePriceOnBlur (event,recipe) {
        const {newPrice,active_role} = this.state;
        const {dispatch} = this.props;

        let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
        let price_origin = recipe.selling_price;
        price_origin = Number(price_origin).toFixed(3)

        const title = <FormattedMessage id="recipes.price.check.error.title"
                                        defaultMessage='Price update error'/>
        
        if (this.assertPriceField(price) && (price!=price_origin)){
                if (active_role!= GUEST){

                    const recipe_data = {
                        id:recipe.id,
                        name:recipe.name,
                        selling_price: Number(trim(price).replace(/,/g, '.')).toFixed(3),
                    }

                    dispatch(recipesActions.update(recipe.id,recipe_data));
                    recipe.selling_price = price
                    setTimeout(() => {
                        dispatch(recipesActions.getAll(true));
                    },500);
        
                }else{
                    dispatch(alertActions.error({title:'recipes.price.privilege.error.message',
                                                content:'recipes.price.check.error.title'}));  
                }
                this.setState({ editCellRecipePrice: false });
        }else{
            if (Math.trunc(price).toString().length>=9){
               const content =  <FormattedMessage id="recipes.price.check.error.message"
                                                 defaultMessage='Price field is invalid'/>
                dispatch(alertActions.error({title:'recipes.price.check.error.title',
                                            content:'recipes.price.check.error.message'}));   
            }
            this.setState({ editCellRecipePrice: false });
        }
    }


    currencySymbol(){    
        const {currency} = this.state;
        const {intl} = this.props;
        let output_currency_symbol = ''
    
        if (currency == 'None') 
            return output_currency_symbol
    
        let value = Intl.NumberFormat(intl.locale,{
                                                  style:'currency',
                                                  currency: currency,
                                                  currencyDisplay: 'symbol',
                                      }).format(0)
        value = value.replace(/,/g, '.');
        value +='' 
        value = value.replace('.','')
    
        let regexp = /\D+/g;
        let symbol = [...value.matchAll(regexp)];      
        
        if (symbol && symbol.length > 0)
          output_currency_symbol = symbol[0][0]
        
        return ''+output_currency_symbol
      }


    addRecipesRows(recipes){
        const {currency,allowMultipleSelection,editCellRecipePrice,editCellIndexRecipePrice,newPrice} = this.state;

        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={4} /*textAlign='center'*/>
                         {allowMultipleSelection && 
                            <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,recipe.id)}
                                   checked={this.isChecked(recipe.id)}
                                   style={{'transform': 'scale(1.1)'}}
                            />}
                            <span style={{marginLeft:'0.7em'}}>
                            <Icon name='food' color='blue'/>
                            <a href={"/recipedetails/"+recipe.id}
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               > {recipe.name.length >= 65 && 
                                recipe.name.substring(0,65) + '..'}
                                {recipe.name.length < 65 && 
                                recipe.name}
                            </a></span>
                            <p style={{'color':'#b5b1b1',marginLeft:'3em'}}><i>{recipe.ref_internal}</i>
                            {recipe.weight_variation_active &&
                                <Popup trigger={<Button  compact floated='right'  
                                                        size='tiny'
                                                        color='teal'>
                                                        {(recipe.weight_variation_type == 'loss') && ' - '}
                                                        {(recipe.weight_variation_type == 'gain') && ' + '}
                                                        {Number(recipe.weight_variation_value*100).toFixed(0)+'%'}                                      
                                                    </Button>}
                                        content={<FormattedMessage id="recipes.popup.view.weight.info.text"
                                                                    defaultMessage='Variation applied'/>}
                                        inverted
                                        size='small'
                                        position='bottom left'/>
                            }</p>
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>  {/* {recipe.category__name } */}
                               {recipe.category__name && recipe.category__name.length >= 18 && 
                                recipe.category__name.substring(0,18) + '..'}
                                {recipe.category__name && recipe.category__name.length < 18 && 
                                recipe.category__name}</Table.Cell>
                        <Table.Cell width={2} textAlign='center'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell>
                        <Table.Cell width={2} textAlign='right' >
                                <FormattedNumber value={recipe.cost_total} style="currency" currencyDisplay="symbol" currency={currency}/>
                                {/* {intl.formatMessage({id:'currency.symbol'})} */}
                                <p style={{'color':'#b5b1b1'}}><i><FormattedNumber value={recipe.cost_unit} style="currency" currency={currency}/></i></p>
                        </Table.Cell>
{/*                         <Table.Cell width={2} textAlign='right' >
                                <FormattedNumber value={recipe.cost_unit} style="currency" currency={currency}/>
                        </Table.Cell> */}
                        <Table.Cell width={2} textAlign='right' onClick={(event) => this.editPriceHandlerInput(event,index,recipe)}>
                            {recipe.is_subrecipe == false &&                 
                            <div>
{/*                                <FormattedNumber value={recipe.selling_price} style="currency" currencyDisplay="symbol" currency={currency}/>
                                 <p style={{'color':'#b5b1b1','font-size':'11px'}}><i> <FormattedNumber value={Number(recipe.selling_price)/(1 + Number(recipe.selling_vat))} style="currency" currencyDisplay="symbol" currency={currency}/></i></p> */}
                               {(!editCellRecipePrice || (editCellRecipePrice && index !== editCellIndexRecipePrice) ) &&
                                <a href='#'>
                                    <FormattedNumber value={recipe.selling_price} style="currency" currencyDisplay="symbol" currency={currency}/>{/*  {' '}
                                   {isQuantity(recipe.yield_units) &&
                                                 <FormattedMessage id="recipes.cell.margin.selling.toggle.quantity.parts.title"
                                                                  defaultMessage='/part'/>}
                                    {!isQuantity(recipe.yield_units) &&
                                                 <FormattedMessage id="recipes.cell.margin.selling.toggle.notquantity.parts.title"
                                                                  defaultMessage='/recipe'/>}  */}
                                    <p style={{'color':'#b5b1b1','font-size':'11px'}}><i><FormattedNumber value={Number(recipe.selling_price)/(1+Number(recipe.selling_vat))} style="currency" currencyDisplay="symbol" currency={currency}/></i></p>
                                    </a>}
                                
                                    {editCellRecipePrice && index === editCellIndexRecipePrice && 
                                    <OutsideClickHandler onOutsideClick={(event) => this.updatePriceOnBlur(event,recipe)}>                    
                                        {/* <Form>
                                        <Form.Group widths='equal'> */}
                                                    <Input type="text"
                                                            id="quantity"
                                                            value={newPrice}
                                                            onChange={this.onNewPrice}
                                                            onBlur={(event) => this.updatePriceOnBlur(event,recipe)} 
                                                            onKeyPress={(event) => this.updatePriceOnEnter(event,recipe)} 
                                                            autoFocus
                                                            style={{height:'2.6em',minWidth:'6em',maxWidth:'6em'}}
                                                            label={{ basic: false, content: this.currencySymbol() }}
                                                            labelPosition='right'
                                                    />{/* <div style={{'marginTop':'0.4em'}} >{this.currencySymbol()}</div> */}
                                        {/* </Form.Group>
                                        </Form>  */}
                                     </OutsideClickHandler>}
                                 </div>} 
                            {recipe.is_subrecipe == true &&      
                                <FormattedMessage id="recipes.cell.margin.info.text"
                                                  defaultMessage='No sell'/>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right' >
                            {recipe.is_subrecipe == false && 
                                <FormattedNumber value={recipe.gross_margin} maximumFractionDigits="1" style="percent"/>}
                             {recipe.is_subrecipe == false && 
                             <p style={{'color':'#b5b1b1','font-size':'11px'}}>
                                <i>{!isQuantity(recipe.yield_units) &&
                                    <FormattedNumber value={(Number(recipe.selling_price)/(1+Number(recipe.selling_vat)) - Number(recipe.cost_total))} style="currency" currencyDisplay="symbol" currency={currency}/>}
                                    {isQuantity(recipe.yield_units) &&
                                    <FormattedNumber value={(Number(recipe.selling_price)/(1+Number(recipe.selling_vat)) - Number(recipe.cost_unit))} style="currency" currencyDisplay="symbol" currency={currency}/>}
                                </i>
                            </p>}
                            {recipe.is_subrecipe == true &&      
                                <FormattedMessage id="recipes.cell.margin.info.text"
                                                  defaultMessage='No sell'/>}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                           <Popup trigger=
                                  {<Icon color='blue' id={index} name='folder open outline' style={{cursor:'pointer'}} size='large'
                                             onClick={(event) => this.seeRecipe(event,recipe.id)}/>
                                      }
                                    //header='See Recipe details'
                                    content={<FormattedMessage id="recipes.popup.view.text"
                                                                     defaultMessage='Detailed view'/>}
                                    inverted/>&nbsp;&nbsp;
{/*                              <Popup trigger=
                                     {<Icon color='blue' id={index} name='edit outline' style={{cursor:'pointer'}} size='large'
                                                        onClick={(event) => this.editRecipe(event,recipe.id)}/>
                                     }
                                    //header='Edit Recipe details'
                                    content={<FormattedMessage id="recipes.popup.edit.text"
                                                                     defaultMessage='Edit'/>}
                                    inverted/>&nbsp; */}
                            <Popup trigger=
                                    {<Icon color='blue' id={index} name='newspaper outline' style={{cursor:'pointer'}} size='large'
                                                onClick={(event) => this.downloadRecipe(event,recipe.id)}/>
                                    }
                                    //header='See Recipe details'
                                    content={<FormattedMessage id="recipes.popup.download.text"
                                                               defaultMessage='Export recipe template'/>}
                                    inverted
                                    position='top right'/>&nbsp;&nbsp;&nbsp;&nbsp;
                        
{/*                             <Popup trigger=
                                     {<Icon color='red' id={index} name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                        onClick={(event) => this.deleteClickHandler(event,index)}/>
                                      }
                                    //header='Delete Recipe details'
                                    content={<FormattedMessage id="recipes.popup.delete.text"
                                                               defaultMessage='Delete'/>}
                                    inverted/>&nbsp; */}
                           <Popup trigger=
                                    {<Dropdown id={index}
                                               trigger={trigger}
                                               pointing='top right'
                                               icon={null}
                                               value = {null}
                                               options={optionsPlus}
                                               label='plus'
                                               onChange={(event,data) => this.onOptionsMenu(event,data,index,recipe.id)} />}
                                    content={<FormattedMessage id="recipes.popup.options.text"
                                                               defaultMessage='Options'/>}
                                    inverted/> &nbsp;&nbsp;
 {/*                        // -- Implementation with new page for deletion
                            <Button id={index} className="ui small icon button" 
                                onClick={(event) => this.deleteRecipe(event,recipe.id)}>
                                <i className="trash icon"></i>
                            </Button>*/}
                            <Modal id={index}
                                   open={this.state.activeModal === index}
                                   dimmer='true' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   //centered={true}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="recipes.modal.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="recipes.modal.deletion.message"
                                                         defaultMessage='Really sure to delete the recipe {name} ?'
                                                         values={{name:recipe.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey}//basic color='grey' 
                                            onClick={this.hideModal}>
                                            <FormattedMessage id="recipes.modal.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed}//basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="recipes.modal.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteRecipe(event,recipe.id)}/>
                                </Modal.Actions>
                            </Modal> 
                            </Table.Cell>
                     </Table.Row>
                );
        });
    }


    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }
    
    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    currencySymbol(){    
        const {currency} = this.state;
        const {intl} = this.props;
        let output_currency_symbol = ''
    
        if (currency == 'None') 
            return output_currency_symbol
    
        let value = Intl.NumberFormat(intl.locale,{
                                                  style:'currency',
                                                  currency: currency,
                                                  currencyDisplay: 'symbol',
                                      }).format(0)
        value = value.replace(/,/g, '.');
        value +='' 
        value = value.replace('.','')
    
        let regexp = /\D+/g;
        let symbol = [...value.matchAll(regexp)];      
        
        if (symbol && symbol.length > 0)
          output_currency_symbol = symbol[0][0]
        
        return output_currency_symbol
    }

    render() { 
        const { recipes,categoriesRecipe,alert,dispatch,loading_r,loading_cr,intl,updating } = this.props;
        const { newItems,filtering,categorySelected,nameSelected,isMobile,page,itemsPerPage,
                selectionList,isCheckedAll,actionSelected ,allowMultipleSelection,typeSelected,codeSelected,currency} = this.state;

        let buttons = [];
		let className = "floating-menu";
		let icon = <Icon name='add'/>;
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;
            buttons.push(<FloatingMenuItemOther label={<FormattedMessage id="recipes.floatingmenu.item3.title" defaultMessage="Create an order"/>}  
                                                 icon={<Icon name='cart'/>} 
                                                 action={this.createOrder} 
                                                 mobile={isMobile}/>);
            buttons.push(<FloatingMenuItemOther label={<FormattedMessage id="recipes.floatingmenu.item2.title" defaultMessage= "Create an ingredient"/>}  
                                                icon={<Icon name='shopping basket'/>} 
                                                action={this.createIngredient} 
                                                mobile={isMobile} key="i1"/>);
            buttons.push(<FloatingMenuItem label={<FormattedMessage id="recipes.floatingmenu.item1.title" defaultMessage='Create a recipe'/>} 
                                            icon={<Icon name='food'/>} 
                                            action={this.createRecipe} 
                                            mobile={isMobile}/>);
            
		}
		buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 

        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering && recipes && recipes.items){
            length = recipes.items.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = recipes.items.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            if (begin > length) begin=0;
            items = newItems.slice(begin,end);
        }


        //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

        return ( 
                     <SideLeftUncoverRecipes>
                        <Grid stackable style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                            <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="recipes.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="recipes.link.recipes"
                                                              defaultMessage='Recipes'/></Breadcrumb.Section>
                            </Breadcrumb>
                            </Grid.Row>
                             <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_pan} style={{width:'52px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="recipes.page.title"
                                                              defaultMessage='Recipes'/>
                                            <Header.Subheader><FormattedMessage id="recipes.page.subtitle"
                                                              defaultMessage='and sub-recipes'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                             </Grid.Row> 
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                           {/*  <Grid.Row columns={1}/>
                            <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column > 
                                    <p style={styles.FontLato13}><FormattedMessage id="recipes.filter.name.title"
                                                         defaultMessage='Search (one or multiple words)'/></p>
                                    <FormattedMessage id="recipes.filter.byname.placeholder"
                                                        defaultMessage='Type at least 2 letters per each word'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                disabled={this.isUnSubscribedUser()}
                                                style={{minWidth:'25em',maxWidth:'30em', minHeight:'3em'}} ><Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column width={2}/> 
                                <Grid.Column width={8}> 
                                   <p style={styles.FontLato13}><FormattedMessage id="recipes.filter.title"
                                                         defaultMessage='Filter by'/></p>
                                    <Dropdown
                                        button basic
                                        className='icon'
                                        labeled
                                        icon={{name:'filter', color:'teal'}}
                                        options={filterOptions}
                                        value = {typeSelected}
                                        onChange={this.filterTypeList}
                                        style={{'font-size':'14px'}}/>
                                    {typeSelected == 'Category' && 
                                        <FormattedMessage id="recipes.filter.category.placeholder"
                                                        defaultMessage='Select'>
                                        {placeholder =>  
                                                <Dropdown selection search
                                                        options= {categoriesRecipe && categoriesRecipe.options} 
                                                        value= {categorySelected && categorySelected.id}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{'font-size':'14px'}}
                                                        //style={{minWidth:'18em',maxWidth:'18em', minHeight:'3em', maxHeight:'3em'}}
                                                        />}
                                    </FormattedMessage>}
                                    {typeSelected == 'InternalRef' && 
                                        <FormattedMessage id="recipes.filter.supplierref.placeholder"
                                                         defaultMessage='Search'>
                                        {placeholder =>  
                                                    <Input placeholder={placeholder}
                                                            value={codeSelected}
                                                            onChange={this.filterInternalRefList}
                                                            disabled={this.isUnSubscribedUser()}
                                                            style={{'font-size':'14px'}}/>}
                                    </FormattedMessage>}
                                </Grid.Column>
{/*                                 <Grid.Column> 
                                   <p style={styles.FontLato13}><FormattedMessage id="recipes.filter.category.title"
                                                         defaultMessage='Filter by category'/></p>
                                    <div><FormattedMessage id="recipes.filter.category.placeholder"
                                                        defaultMessage='Select a category'>
                                        {placeholder =>  
                                                <Dropdown selection search size='large'
                                                        options= {categoriesRecipe && categoriesRecipe.options} 
                                                        value= {categorySelected && categorySelected.id}
                                                        placeholder={placeholder}
                                                        onChange={this.filterCategoryList}
                                                        clearable
                                                        disabled={this.isUnSubscribedUser()}
                                                        style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em','font-size':'14px'}}>
                                                    </Dropdown>}
                                         </FormattedMessage>
                                   </div> 
                                </Grid.Column>  */}
                            </Grid.Row>
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    <div className={className}>
                                        {buttons}
                                    </div>
                                </Grid.Column>
                             </Grid.Row>
                            <Grid.Row columns={1} /* style={{'overflow-x': 'auto'}} */>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    {selectionList.length > 0 && 
                                            <FormattedMessage id="recipes.action.main.placeholder"
                                                            defaultMessage='{number} selected'
                                                            values={{number:selectionList.length}}>
                                                {placeholder =>  
                                                        <Dropdown selection floating labeled
                                                                options= {[
                                                                    /* { key: 'none', icon: 'select', 
                                                                        text: <FormattedMessage id="recipes.action.info.nbitems.text"
                                                                                                defaultMessage='{number} selected'
                                                                                                values={{number:selectionList.length}}/>, value: 'none' }, */
                                                                    { key: 'none', text: ' ', value: 'none' },
                                                                    { key: 'category', icon:  {name:'sitemap',color:'yellow'},
                                                                              text: <FormattedMessage id="recipes.action.category.text"
                                                                                            defaultMessage='Category'/>, value: 'category' },
/*                                                                     { key: 'pos', icon:  {name:'tv',color:'teal'},
                                                                              text: <FormattedMessage id="recipes.action.pos.text"
                                                                                            defaultMessage='Send to POS'/>, value: 'pos' }, */
                                                                    { key: 'delete', icon:  {name:'trash alternate outline',color:'red'}, 
                                                                        text: <FormattedMessage id="recipes.action.delete.text"
                                                                                    defaultMessage='Delete'/>, value: 'delete' },]}
                                                                value= {actionSelected}
                                                                text={placeholder}
                                                                className='icon'
                                                                onChange={this.selectActionList}
                                                                style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                            </FormattedMessage>}
{/*                                         <span>{actionSelected !='' && selectionList.length > 0 &&
                                                <Button compact 
                                                className="ui icon button"
                                                style={{marginLeft:'0.5em', 'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#498caa',color:'white'}}
                                                onClick={this.submitAction}>
                                                <FormattedMessage id="recipes.action.button.validate.text"
                                                                   defaultMessage='Submit'/>
                                        </Button>}*/}
                                        {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="recipes.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}{/*</span>
                                     {selectionList.length > 0 &&
                                        <span  style={{marginLeft:'0.5em', color:'grey'}}>{selectionList.length}{' '}
                                                <FormattedMessage id="recipes.action.info.nbitems.text"
                                                                  defaultMessage='item(s)'/> </span>
                                  } */}
                                   <Dimmer.Dimmable>
                                        <Dimmer active={((loading_r || loading_cr))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                                    <Table //definition
                                           //selectable 
                                           //stackable
                                           //fixed 
                                           unstackable
                                           compact 
                                           striped 
                                           singleLine
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell>Number #</Table.HeaderCell> */}
                                                <Table.HeaderCell width={4} /*textAlign='center'*/>
                                                {allowMultipleSelection && <Input type='checkbox'
                                                            onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.1)'}}
                                                            checked={isCheckedAll}/>}
                                                        <span style={{marginLeft:'0.7em'}}><FormattedMessage id="recipes.table.header.column.name"
                                                                          defaultMessage='Name'/></span>
                                                         <p style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'2em'}}><i><FormattedMessage id="recipes.table.header.column.reference"
                                                                          defaultMessage='Reference code'/></i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                        <FormattedMessage id="recipes.table.header.column.category"
                                                                          defaultMessage='Category'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                        <FormattedMessage id="recipes.table.header.column.yield"
                                                                          defaultMessage='Yield'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                        <FormattedMessage id="recipes.table.header.column.recipecost"
                                                                          defaultMessage='Recipe Cost'/>
                                                        <p style={{'color':'#b5b1b1','font-size':'12px'}}><i><FormattedMessage id="recipes.table.header.column.unitcost"
                                                                          defaultMessage='Unit Cost'/></i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                        <FormattedMessage id="recipes.table.header.column.selllingprice"
                                                                          defaultMessage='Selling Price'/>
                                                        <p style={{'color':'#b5b1b1','font-size':'12px'}}><i><FormattedMessage id="recipes.table.header.column.sellingnovat"
                                                                          defaultMessage='VAT excluded'/></i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                        <FormattedMessage id="recipes.table.header.column.margin"
                                                                          defaultMessage='Gross margin %'/>
                                                        <p style={{'color':'#b5b1b1','font-size':'12px'}}><i><FormattedMessage id="recipes.table.header.column.margincurrency"
                                                                          defaultMessage='Gross margin'/>{' '}{this.currencySymbol()}</i></p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} >
                                                   &nbsp; &nbsp; &nbsp; &nbsp; 
                                                   <FormattedMessage id="recipes.table.header.column.form"
                                                                     defaultMessage='Recipe form' />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                      <Table.Body>
{/*                                         {!filtering && recipes && recipes.items &&
                                                this.addRecipesRows(recipes.items)
                                        }
                                        {filtering && newItems &&
                                                this.addRecipesRows(newItems)
                                        } */}
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="recipes.table.header.row.noitems"
                                                                            defaultMessage='No recipes'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                            </Table.Row>}
                                        {items &&
                                            this.addRecipesRows(items)
                                        }
                                        </Table.Body>
                                      {this.isUnSubscribedUser() && items &&
                                        <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='8'>
                                                <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                        <Image src={img_performance} size ='small' rounded/>
                                                        <br/>
                                                        <Message.Content style={{marginLeft:'1em'}}>
                                                            <Message.Header color='black'>
                                                                <FormattedMessage id="recipes.premium.title.message.upgradeplan"
                                                                                    defaultMessage='Recipes limit exceeded'/> 
                                                            </Message.Header>
                                                            <br/>
                                                            {recipes && recipes.items && <div style={styles.FontLato13}>
                                                                <FormattedMessage id="recipes.premium.message.upgradeplan.line1"
                                                                                  defaultMessage='Your account has'/>{' '}{recipes.items.length}{' '}
                                                                <FormattedMessage id="recipes.premium.message.upgradeplan.line2"
                                                                                  defaultMessage='recipes but your plan only permits'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 

                                                            </div>}
                                                            <br/><br/>
                                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="recipes.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                                            </Button>                                      
                                                        </Message.Content>
                                                    </Message>
                                                </Table.HeaderCell>
                                        </Table.Footer>}
                                    </Table>  
                                    </Dimmer.Dimmable>                               
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={options} 
                                                  //simple 
                                                  item
                                                  inline
                                                  //icon='list'
                                                  //button 
                                                  fluid
                                                  //search
                                                  //header='Page'
                                                  //text='ingredients'
                                                  onChange={this.onElemPerPageChange}
                                                  disabled={this.isUnSubscribedUser()}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="recipes.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="recipes.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={1}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                        disabled={this.isUnSubscribedUser()}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="recipes.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="recipes.modal.select.deletion"
                                                         defaultMessage='Really sure to delete the selected ingredient(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="recipes.modal.select.deletion.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="recipes.modal.select.deletion.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                        <Modal  open={this.state.actionModalCategory}
                                   dimmer='default' 
                                   size='tiny'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                    <Header icon='sitemap' content={<FormattedMessage id="recipes.modal.select.category.title"
                                                                                    defaultMessage='Select category'/>} />
                                    <Modal.Content>
                                        <Form style={{paddingLeft:'1em',paddingBottom:'3em'}}>
                                          <Form.Field width={8}>
                                            <label><FormattedMessage id="recipes.modal.select.category"
                                                                      defaultMessage='Category'/>
                                            </label>
                                            <FormattedMessage id="recipes.modal.select.category.placeholder"
                                                              defaultMessage='Select'>
                                                  {placeholder =>   
                                                          <Form.Dropdown selection search //scrolling 
                                                                  options={categoriesRecipe && categoriesRecipe.options} 
                                                                  value = {categorySelected && categorySelected.id} 
                                                                  placeholder={placeholder} 
                                                                  onChange={this.onCategoryChange}
                                                                  style={{'font-size':'13px'}}
                                                                  clearable
                                                            />}
                                            </FormattedMessage>
                                          </Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                          <Form.Field width={8}></Form.Field>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='grey' onClick={this.hideModal}>
                                            <FormattedMessage id="recipes.modal.select.category.button.cancel"
                                                            defaultMessage='Cancel'/>
                                        </Button>
                                       {updating && <Button color='teal' loading
                                            labelPosition='right' 
                                            content={<FormattedMessage id="recipes.modal.select.category.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectCategory}
                                            >
                                        </Button>}
                                        {!updating && <Button color='teal'
                                            labelPosition='right' 
                                            content={<FormattedMessage id="recipes.modal.select.category.button.update"
                                                                        defaultMessage='Update'/>}
                                            icon='checkmark' 
                                            onClick={this.actionSelectCategory}
                                            >
                                        </Button>}
                                    </Modal.Actions>
                            </Modal> 
{/*                             <Modal open={this.state.actionModalPos === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='tv' content={<FormattedMessage id="recipes.modal.select.pos.title"
                                                                                defaultMessage='Send to POS'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="recipes.modal.select.pos"
                                                         defaultMessage='Really sure to send to POS the selected recipe(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="recipes.modal.select.pos.button.no"
                                                              defaultMessage='No'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="recipes.modal.select.pos.button.yes"
                                                                    defaultMessage='Yes'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.actionSelectSendPOS(event)}
                                        />
                                    </Modal.Actions>
                        </Modal>  */}
                     </SideLeftUncoverRecipes>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,categoriesRecipe,alert,dispatch } = state;
    //const { loading_i } = state.ingredients;
    const { loading_r,updating} = state.recipes;
    //const { loading_ci } = state.categoriesIngredient;
    const { loading_cr } = state.categoriesRecipe;
    const { subscription } = state.payment;
    return {
        recipes,
        categoriesRecipe,
        alert,
        dispatch,
        loading_r,
        //loading_i,
        //loading_ci,
        loading_cr,
        subscription,
        updating
    };
}

const connectedRecipes = injectIntl(connect(mapStateToProps)(Recipes));
export { connectedRecipes as Recipes };
 