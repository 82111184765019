import { recipeConstants } from '../_constants';
//import _ from 'lodash'
//import React, { Component } from 'react';
//import { Button} from 'semantic-ui-react';

//const RECIPE ='RECIPE';

export function recipes(state = {}, action) {
  //let sequence = 0
  switch (action.type) {
    case recipeConstants.GETALL_REQUEST:
      return {
        loading_r: true
      };
    case recipeConstants.GETALL_SUCCESS:
/*       let options=[];
      let searchList=[];
      action.recipes.map((item,index)=> {
        options[index]= {key:index,text:'Recipe: '+item.name,value:item.name,type:'recipe'};
        searchList.push({
          name: RECIPE,
          title: item.name,
          //price: 'cost: ' + item.cost_total + '€',
          price: item.cost_total,
          description: RECIPE,
          image: <Button icon='book' size='large' />
        });  
      });*/

      let sorted_items = action.recipes
      if (sorted_items){
        sorted_items = sorted_items.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }

      return {
        ...state,
        loading_r: false,
        items: sorted_items,
        //options: options,
        //searchList: searchList
      };
    case recipeConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case recipeConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case recipeConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: action.recipes,
      };
    case recipeConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case recipeConstants.ADD_REQUEST:
      return {
        adding: true
      };
    case recipeConstants.ADD_SUCCESS:
      return {
        added: true//state.items.filter(recipe => recipe.id !== action.id)
      };
    case recipeConstants.ADD_FAILURE:
      return { 
        error: action.error
      };

     case recipeConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case recipeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,//state.items.filter(recipe => recipe.id !== action.id)
        selected: action.recipes,
      };
    case recipeConstants.UPDATE_FAILURE:
      return { 
        error: action.error
      };
  
    case recipeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to recipe being deleted
      return {
        ...state,
        deleting: true
/*         ...state,
        items: state.items.map(recipe =>
          recipe.id === action.id
            ? { ...recipe, deleting: true }
            : recipe
        ) */
      };
    case recipeConstants.DELETE_SUCCESS:
      // remove deleted recipe from state
      return {
        ...state,
        deleting: false,
        deleted: true
        //items: state.items.filter(recipe => recipe.id !== action.id)
      };
    case recipeConstants.DELETE_WARN:
        return {
          deleting: false,
          deleted:true
      };
    case recipeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        error: action.error
/*         ...state,
        items: state.items.map(recipe => {
          if (recipe.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...recipeCopy } = recipe;
            // return copy of user with 'deleteError:[error]' property
            return { ...recipeCopy, deleteError: action.error };
          }

          return recipe;
        }) */
      };

    case recipeConstants.WEIGHT_VARIATION_REQUEST:
        return {
          updating: true
        };
    case recipeConstants.WEIGHT_VARIATION_SUCCESS:
        return {
          updating: false,
          updated: true,
          selected: action.recipes,
        };
    case recipeConstants.WEIGHT_VARIATION_FAILURE:
        return { 
          error: action.error
        };

    case recipeConstants.LABEL_TAG_REQUEST:
        return {
          updating: true
        };
    case recipeConstants.LABEL_TAG_SUCCESS:
        return {
          updating: false,
          updated: true,
          selected: action.recipes,
        };
    case recipeConstants.LABEL_TAG_FAILURE:
        return { 
          error: action.error
        };

    case recipeConstants.DEDUCE_ORDER_REQUEST:
          return {
            updating: true
          };
    case recipeConstants.DEDUCE_ORDER_SUCCESS:
          return {
            updating: false,
            updated: true,
            order: action.recipes,
          };
    case recipeConstants.DEDUCE_ORDER_FAILURE:
          return { 
            error: action.error
          };

    case recipeConstants.ADDSTOCK_ORDER_REQUEST:
        return {
              updating: true
            };
    case recipeConstants.ADDSTOCK_ORDER_SUCCESS:
        return {
              updating: false,
              updated: true,
              order: action.recipes,
            };
    case recipeConstants.ADDSTOCK_ORDER_FAILURE:
        return { 
              error: action.error
            };

    case recipeConstants.DESTOCK_ORDER_REQUEST:
        return {
                updating: true
              };
    case recipeConstants.DESTOCK_ORDER_SUCCESS:
        return {
                updating: false,
                updated: true,
                order: action.recipes,
              };
    case recipeConstants.DESTOCK_ORDER_FAILURE:
        return { 
                error: action.error
              };
    case recipeConstants.STOCK_RECIPES_ORDER_REQUEST:
        return {
                  updating: true
                };
    case recipeConstants.STOCK_RECIPES_ORDER_SUCCESS:
        return {
                  updating: false,
                  updated: true,
                  order: action.recipes,
                };
    case recipeConstants.STOCK_RECIPES_ORDER_FAILURE:
         return { 
                  error: action.error
                };
    case recipeConstants.STATS_REQUEST:
      return {
        retrieving: true
      };
    case recipeConstants.STATS_SUCCESS:
      return {
        ...state,
        retrieving: false,
        stats: action.recipes

      };
    case recipeConstants.STATS_FAILURE:
      return { 
        error: action.error
      };

    case recipeConstants.GET_PDF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case recipeConstants.GET_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.recipes,
      };
    case recipeConstants.GET_PDF_FAILURE:
      return { 
        error: action.error
      };

/*     case recipeConstants.GET_ORDER_REQUEST:
      return {
        loading: true
      };
    case recipeConstants.GET_ORDER_SUCCESS:
      return {
        loading: false,
        response: action.recipes,
      };
    case recipeConstants.GET_ORDER_FAILURE:
      return { 
        error: action.error
      }; */

    case recipeConstants.GET_ORDERS_REQUEST:
      return {
          loading: true
        };
    case recipeConstants.GET_ORDERS_SUCCESS:
      return {
          loading: false,
          orders: action.recipes,
        };
    case recipeConstants.GET_ORDERS_FAILURE:
      return { 
          error: action.error
        };

     case recipeConstants.GET_ORDER_REQUEST:
          return {
              loading: true
            };
    case recipeConstants.GET_ORDER_SUCCESS:
          return {
              loading: false,
              order: action.recipes,
            };
    case recipeConstants.GET_ORDER_FAILURE:
          return { 
             error: action.error
          };

    case recipeConstants.COUNT_ORDERS_REQUEST:
          return {
             ...state,
              counting: true
            };
    case recipeConstants.COUNT_ORDERS_SUCCESS:
          return {
              ...state,
              counting: false,
              order_count: action.recipes
            };
    case recipeConstants.COUNT_ORDERS_FAILURE:
          return { 
              error: action.error
            };

    case recipeConstants.UPDATE_ORDER_REQUEST:
              return {
                  loading: true
                };
    case recipeConstants.UPDATE_ORDER_SUCCESS:
              return {
                  loading: false,
                  order: action.recipes,
                };
    case recipeConstants.UPDATE_ORDER_FAILURE:
              return { 
                 error: action.error
              };

    case recipeConstants.ADD_INGREDIENT_ORDER_REQUEST:
                return {
                    adding: true
                  };
    case recipeConstants.ADD_INGREDIENT_ORDER_SUCCESS:
                return {
                  adding: false,
                  order: action.recipes,
                  };
    case recipeConstants.ADD_INGREDIENT_ORDER_FAILURE:
                return { 
                   error: action.error
                };

    case recipeConstants.SAVE_ORDER_REQUEST:
                return {
                    loading_o: true
                  };
    case recipeConstants.SAVE_ORDER_SUCCESS:
                return {
                    loading_o: false,
                  };
    case recipeConstants.SAVE_ORDER_FAILURE:
                return { 
                   error: action.error
                };

    case recipeConstants.EXPORT_CSV_REQUEST:
          return {
                exporting:true
          };
    case recipeConstants.EXPORT_CSV_SUCCESS:
          return {
              exporting:false,
          };
    case recipeConstants.EXPORT_CSV_FAILURE:
          return {
                error: action.error
          };
      
    case recipeConstants.EXPORT_XLSX_REQUEST:
          return {
                exporting:true
          };
    case recipeConstants.EXPORT_XLSX_SUCCESS:
          return {
                  exporting:false,
          };
    case recipeConstants.EXPORT_XLSX_FAILURE:
          return {
                error: action.error
          };
    
     case recipeConstants.UPDATE_INSTRUCTIONS_REQUEST:
      return {
        updating: true
      };
    case recipeConstants.UPDATE_INSTRUCTIONS_SUCCESS:
      return {
        updating: false,
        updated: true,
      };
    case recipeConstants.UPDATE_INSTRUCTIONS_FAILURE:
      return { 
        error: action.error
      };

     case recipeConstants.UPDATE_IMAGE_REQUEST:
        return {
          ...state,
          updating: true
        };
     case recipeConstants.UPDATE_IMAGE_SUCCESS:
        return {
          ...state,
          updating: false,
          updated: true,
        };
     case recipeConstants.UPDATE_IMAGE_FAILURE:
        return { 
          error: action.error
        };
       case recipeConstants.GET_IMAGE_REQUEST:
          return {
            ...state,
            loading_image: true
          };
      case recipeConstants.GET_IMAGE_SUCCESS:
          return {
            ...state,
            loading_image: false,
            //loading: true,
            image:  action.recipes,
          };
      case recipeConstants.GET_IMAGE_FAILURE:
          return { 
            error: action.error
          };
      
      //MENUS reducers
      case recipeConstants.GETALL_MENU_REQUEST:
         return {
              loading: true
          };
      case recipeConstants.GETALL_MENU_SUCCESS:
        let sorted_menus = action.recipes
        if (sorted_menus){
          sorted_menus = sorted_menus.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          })
        }
        return {
          loading: false,
          menus: sorted_menus,
        };
      case recipeConstants.GETALL_MENU_FAILURE:
        return { 
          error: action.error
        };
       case recipeConstants.GET_MENU_REQUEST:
          return {
               loading: true
           };
       case recipeConstants.GET_MENU_SUCCESS:
          let new_menu_r_items = action.recipes.recipes
          let new_menu_i_items = action.recipes.ingredients
          new_menu_r_items.sort(function (a, b) {
            if (a.recipe.category  === null) 
              return 1;
            else if ( b.recipe.category === null)
              return -1;
            else if (a.recipe.category && b.recipe.category){
              return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
              //return a.recipe.category.name.toLowerCase().localeCompare(b.recipe.category.name.toLowerCase());
            }
          })
          new_menu_i_items.sort(function (a, b) {
            if (a.ingredient.category  === null) 
              return 1;
            else if ( b.ingredient.category === null)
              return -1;
            else if (a.ingredient.category && b.ingredient.category){
              return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
              //return a.recipe.category.name.toLowerCase().localeCompare(b.recipe.category.name.toLowerCase());
            }
          })

         return {
           loading: false,
           menu: action.recipes,
           menu_r_items: new_menu_r_items,
           menu_i_items: new_menu_i_items,
         };
       case recipeConstants.GET_MENU_FAILURE:
         return { 
           error: action.error
         };
       case recipeConstants.ADD_MENU_REQUEST:
          return {
            adding: true
          };
       case recipeConstants.ADD_MENU_SUCCESS:
          return {
            added: true
          };
       case recipeConstants.ADD_MENU_FAILURE:
          return { 
            error: action.error
          };
        case recipeConstants.UPDATE_MENU_REQUEST:
            return {
              updating: true
            };
        case recipeConstants.UPDATE_MENU_SUCCESS:
            new_menu_r_items = action.recipes.recipes
            new_menu_i_items = action.recipes.ingredients
            new_menu_r_items.sort(function (a, b) {
            if (a.recipe.category  === null) 
                  return 1;
            else if ( b.recipe.category === null)
                  return -1;
            else if (a.recipe.category && b.recipe.category){
                  return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                }
            })
            new_menu_i_items.sort(function (a, b) {
              if (a.ingredient.category  === null) 
                return 1;
              else if ( b.ingredient.category === null)
                return -1;
              else if (a.ingredient.category && b.ingredient.category){
                return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
              }
            })
            return {
              updating: false,
              menu: action.recipes,
              menu_r_items: new_menu_r_items,
              menu_i_items: new_menu_i_items
            };
        case recipeConstants.UPDATE_FAILURE:
            return { 
              error: action.error
            };

       case recipeConstants.DELETE_MENU_REQUEST:
            return {
              deleting: true
            };
       case recipeConstants.DELETE_MENU_SUCCESS:
            return {
              deleted: true
            };
       case recipeConstants.DELETE_MENU_FAILURE:
            return {
              error: action.error
            };
       case recipeConstants.ADD_RECIPE_MENU_REQUEST:
              return {
                ...state,
                adding: true
              };
       case recipeConstants.ADD_RECIPE_MENU_SUCCESS:
              new_menu_r_items = action.recipes.recipes;
              new_menu_i_items = action.recipes.ingredients
              new_menu_r_items.sort(function (a, b) {
                if (a.recipe.category  === null) 
                  return 1;
                else if ( b.recipe.category === null)
                  return -1;
                else if (a.recipe.category && b.recipe.category){
                  return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                }
              })
              new_menu_i_items.sort(function (a, b) {
                if (a.ingredient.category  === null) 
                  return 1;
                else if ( b.ingredient.category === null)
                  return -1;
                else if (a.ingredient.category && b.ingredient.category){
                  return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                }
              })
              
              return {
                menu: action.recipes,
                menu_r_items: new_menu_r_items,
                menu_i_items: new_menu_i_items,
                added: true
              };
       case recipeConstants.ADD_RECIPE_MENU_FAILURE:
               return { 
                error: action.error
               };
        case recipeConstants.UPDATE_RECIPE_MENU_REQUEST:
                return {
                  ...state,
                  updating_menu: true
                };
        case recipeConstants.UPDATE_RECIPE_MENU_SUCCESS:
                new_menu_r_items = action.recipes.recipes;
                new_menu_i_items = action.recipes.ingredients;
                //new_menu_r_items = action.recipes[0].recipes;
                //let new_menu_i_items =  action.recipes[0].ingredients;
                new_menu_r_items.sort(function (a, b) {
                  if (a.recipe.category  === null) 
                    return 1;
                  else if ( b.recipe.category === null)
                    return -1;
                  else if (a.recipe.category && b.recipe.category){
                    return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                  }
                })
                new_menu_i_items.sort(function (a, b) {
                  if (a.ingredient.category  === null) 
                    return 1;
                  else if ( b.ingredient.category === null)
                    return -1;
                  else if (a.ingredient.category && b.ingredient.category){
                    return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                  }
                })
/*                 let sorting_params = action.recipes[1];
                new_menu_r_items =  _.sortBy(new_menu_r_items, sorting_params.column)
                new_menu_i_items =  _.sortBy(new_menu_i_items, sorting_params.column)

                if (sorting_params.direction === 'descending'){
                  new_menu_r_items =  new_menu_r_items.reverse()
                  new_menu_i_items =  new_menu_i_items.reverse()
                } */

                return {
                  updating_menu: false,
                  menu: action.recipes,
                  menu_r_items: new_menu_r_items,
                  menu_i_items: new_menu_i_items,
                  //column: sorting_params.column,
                  //direction: sorting_params.direction
                };
        case recipeConstants.UPDATE_RECIPE_MENU_FAILURE:
                return { 
                  error: action.error
                };
        case recipeConstants.DELETE_RECIPE_MENU_REQUEST:
                return {
                  ...state,
                  deleting: true
                };
        case recipeConstants.DELETE_RECIPE_MENU_SUCCESS:
                /* new_menu_items = state.menu_items.filter(recipe => recipe.id != action.id); */
                new_menu_r_items = action.recipes.recipes;
                new_menu_i_items = action.recipes.ingredients;
                new_menu_r_items.sort(function (a, b) {
                  if (a.recipe.category  === null) 
                    return 1;
                  else if ( b.recipe.category === null)
                    return -1;
                  else if (a.recipe.category && b.recipe.category){
                    return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                  }
                })
                new_menu_i_items.sort(function (a, b) {
                  if (a.ingredient.category  === null) 
                    return 1;
                  else if ( b.ingredient.category === null)
                    return -1;
                  else if (a.ingredient.category && b.ingredient.category){
                    return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                  }
                })

                return {
                  deleted: true,
                  menu: action.recipes,
                  menu_r_items: new_menu_r_items,
                  menu_i_items: new_menu_i_items
                };
        case recipeConstants.DELETE_RECIPE_MENU_FAILURE:
                return { 
                  error: action.error
                };

        case recipeConstants.ADD_INGREDIENT_MENU_REQUEST:
                return {
                    ...state,
                    adding: true
                };
        case recipeConstants.ADD_INGREDIENT_MENU_SUCCESS:
                //let new_menu_i_items = action.recipes.ingredients;
                new_menu_r_items = action.recipes.recipes;
                new_menu_i_items = action.recipes.ingredients;
                new_menu_r_items.sort(function (a, b) {
                  if (a.recipe.category  === null) 
                    return 1;
                  else if ( b.recipe.category === null)
                    return -1;
                  else if (a.recipe.category && b.recipe.category){
                    return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                  }
                })
                new_menu_i_items.sort(function (a, b) {
                  if (a.ingredient.category  === null) 
                    return 1;
                  else if ( b.ingredient.category === null)
                    return -1;
                  else if (a.ingredient.category && b.ingredient.category){
                    return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                  }
                })
                return {
                    menu: action.recipes,
                    menu_r_items: new_menu_r_items,
                    menu_i_items: new_menu_i_items,
                    added: true
                };
        case recipeConstants.ADD_INGREDIENT_MENU_FAILURE:
                return { 
                    error: action.error
                };
        case recipeConstants.UPDATE_INGREDIENT_MENU_REQUEST:
                return {
                    ...state,
                    updating: true
                };
        case recipeConstants.UPDATE_INGREDIENT_MENU_SUCCESS:
                new_menu_i_items = action.recipes.ingredients;
                new_menu_r_items = action.recipes.recipes;
                new_menu_r_items.sort(function (a, b) {
                  if (a.recipe.category  === null) 
                    return 1;
                  else if ( b.recipe.category === null)
                    return -1;
                  else if (a.recipe.category && b.recipe.category){
                    return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                  }
                })
                new_menu_i_items.sort(function (a, b) {
                  if (a.ingredient.category  === null) 
                    return 1;
                  else if ( b.ingredient.category === null)
                    return -1;
                  else if (a.ingredient.category && b.ingredient.category){
                    return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                  }
                })
                return {
                      updated: true,
                      menu: action.recipes,
                      menu_r_items: new_menu_r_items,
                      menu_i_items: new_menu_i_items
                };
        case recipeConstants.UPDATE_INGREDIENT_MENU_FAILURE:
                return { 
                      error: action.error
                };
        case recipeConstants.DELETE_INGREDIENT_MENU_REQUEST:
                return {
                      ...state,
                      deleting: true
                };
        case recipeConstants.DELETE_INGREDIENT_MENU_SUCCESS:
                new_menu_i_items = action.recipes.ingredients;
                new_menu_r_items = action.recipes.recipes;
                new_menu_r_items.sort(function (a, b) {
                  if (a.recipe.category  === null) 
                    return 1;
                  else if ( b.recipe.category === null)
                    return -1;
                  else if (a.recipe.category && b.recipe.category){
                    return a.recipe.category.name.toLowerCase() > b.recipe.category.name.toLowerCase();
                  }
                })
                new_menu_i_items.sort(function (a, b) {
                  if (a.ingredient.category  === null) 
                    return 1;
                  else if ( b.ingredient.category === null)
                    return -1;
                  else if (a.ingredient.category && b.ingredient.category){
                    return a.ingredient.category.name.toLowerCase() > b.ingredient.category.name.toLowerCase();
                  }
                })
                return {
                      deleted: true,
                      menu: action.recipes,
                      menu_r_items: new_menu_r_items,
                      menu_i_items: new_menu_i_items
                };
          case recipeConstants.DELETE_INGREDIENT_MENU_FAILURE:
                return { 
                      error: action.error
                };
          case recipeConstants.CREATE_SUPPLIERS_ORDER_REQUEST:
                return {
                      updating: true
                };
          case recipeConstants.CREATE_SUPPLIERS_ORDER_SUCCESS:
                return {
                      updating: false,
                      updated: true,
                      //order: action.recipes,
                };
          case recipeConstants.CREATE_SUPPLIERS_ORDER_FAILURE:
                return { 
                      error: action.error
                };
          case recipeConstants.COUNT_MENUS_REQUEST:
                  return {
                     ...state,
                      counting: true
                    };
          case recipeConstants.COUNT_MENUS_SUCCESS:
                  return {
                      ...state,
                      counting: false,
                      menu_stats: action.recipes
                    };
          case recipeConstants.COUNT_MENUS_FAILURE:
                  return { 
                      error: action.error
                    };
          case recipeConstants.GETALL_FOR_ORDER_REQUEST:
                   return {
                        ...state,
                        loading_r: true
                    };
          case recipeConstants.GETALL_FOR_ORDER_SUCCESS:                
                  let sorted_items_order = action.recipes
                  //let loading_state = true
                  //sequence += action.sequence

                  //Meaning first request to retrieve is achieved at least
/*                   if (sequence>0){
                    loading_state = false
                  } */

                  if (state.items){
                        sorted_items_order = state.items.concat(sorted_items_order)
                        //console.log(sorted_items_order)
                        //sorted_items_order = sorted_items_order.filter((item, pos) => sorted_items_order.indexOf(item) === pos)
                        var set = new Set(sorted_items_order);
                        sorted_items_order = Array.from(set);
                        //console.log(sorted_items_order)
                  }

                   if (sorted_items_order){
                        sorted_items_order = sorted_items_order.sort(function (a, b) {
                          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        })
                   }

                  return {
                        ...state,
                        loading_r: false,
                        //sequence: sequence,
                        items: sorted_items_order,
                        //options: options,
                        //searchList: searchList
                    };     

        case recipeConstants.GETALL_FOR_ORDER_FAILURE:
              return { 
                    error: action.error
                  };
        case recipeConstants.CATEGORY_ALL_REQUEST:
              return {
                     updating:true
               };
        case recipeConstants.CATEGORY_ALL_SUCCESS:
              return {
                     updating: false,
              };
        case recipeConstants.CATEGORY_ALL_FAILURE:
             return {
                  error: action.error
            };
 /*        case recipeConstants.GET_ESTIMATE_REQUEST:
              return {
                  loading: true
                };
        case recipeConstants.GET_ESTIMATE_SUCCESS:
              return {
                  loading: false,
                  estimate: action.recipes,
                };
        case recipeConstants.GET_v_FAILURE:
              return { 
                 error: action.error
              };
        case recipeConstants.GET_ESTIMATES_REQUEST:
            return {
                loading: true
              };
        case recipeConstants.GET_ESTIMATES_SUCCESS:
            return {
                loading: false,
                estimates: action.recipes,
              };
        case recipeConstants.GET_ESTIMATES_FAILURE:
            return { 
                error: action.error
              };
  
        case recipeConstants.UPDATE_ESTIMATE_REQUEST:
             return {
                      loading: true
                    };
        case recipeConstants.UPDATE_ESTIMATE_SUCCESS:
              return {
                      loading: false,
                      estimate: action.recipes,
                    };
        case recipeConstants.UPDATE_ESTIMATE_FAILURE:
              return { 
                    error: action.error
                  };
        case recipeConstants.ADD_RECIPE_ESTIMATE_REQUEST:
              return {
                        adding: true
                      };
        case recipeConstants.ADD_RECIPE_ESTIMATE_SUCCESS:
              return {
                      adding: false,
                      estimate: action.recipes,
                      };
        case recipeConstants.ADD_RECIPE_ESTIMATE_FAILURE:
              return { 
                       error: action.error
                    }; */
        default:
          return state

  }
}